<template>
  <div class="sidebar-wrapper">
        <div class="text-center" v-if="$store.getters['account/accountData'].role !== 'caleg'">
            <img src="/assets/images/logo-web.png" style="max-width: 156px;" alt="">
        </div>
        <div v-else>
            <div :style="'margin: auto; width: 80px; height: 80px;background:url(\'' + $store.getters['account/accountData'].avatar + '\');background-size: cover; background-position: center; border-radius: 100%;'  "></div>
            <p class="text-center m-0 text-light mt-2">{{ $store.getters['account/accountData'].name }}</p>
        </div>
        <div id="sidebar-menu">
            <ul id="general-menu" class="list-unstyled">
                <li class="text-light">
                    <router-link to="/scan-invoice">
                        <span class="svg-icon" v-html="Icon.Qrcode"></span>Scan QRCode
                    </router-link>
                </li>
                <li class="text-light">
                    <router-link to="/sales">
                        <span class="svg-icon" v-html="Icon.CashRegister"></span>Penjualan
                    </router-link>
                </li>
                <li class="text-light">
                    <router-link to="/crafts">
                        <span class="svg-icon" v-html="Icon.Ring"></span>Tempahan
                    </router-link>
                </li>
                <li class="text-light">
                    <router-link to="/buy-backs">
                        <span class="svg-icon" v-html="Icon.CartPlus"></span>Buy Back
                    </router-link>
                </li>
                <li class="text-light">
                    <router-link to="/stocks">
                        <span class="svg-icon" v-html="Icon.NotesMedical"></span>Stok
                    </router-link>
                </li>
                <li class="text-light">
                    <router-link to="/costumers">
                        <span class="svg-icon" v-html="Icon.Costumer"></span>Costumer
                    </router-link>
                </li>
                <li v-if="$store.getters['account/accountData'].role === Roles.ADMIN" class="text-light">
                    <router-link to="/users">
                        <span class="svg-icon" v-html="Icon.UserAdmin"></span>Pengguna
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import UserAdmin from '@/assets/menus/user-admin';
    import Qrcode from '@/assets/menus/qrcode';
    // import Jewelry from '@/assets/menus/jewelry';
    import NotesMedical from '@/assets/menus/notes-medical';
    import CartPlus from '@/assets/menus/cart-plus';
    import Ring from '@/assets/menus/ring';
    import CashRegister from '@/assets/menus/cash-register';
    import Costumer from '@/assets/menus/costumer';
    import { Roles } from '@/_helpers';

    export default {
        data () {
            return {
                Icon: {
                    UserAdmin,
                    // Jewelry,
                    NotesMedical,
                    Ring,
                    CashRegister,
                    CartPlus,
                    Costumer,
                    Qrcode
                },
                Roles: Roles
            }
        }
    }
</script>